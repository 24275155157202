<template>
    <Head>
      <Title>Error 404</Title>
    </Head>
    <MainLayout pageName="404" parent="404">
      <section class="page-not-found section-b-space">
        <div class="container">
          <div class="row gx-md-2 gx-0 gy-md-0 gy-3">
            <div class="col-md-8 m-auto">
              <div class="page-image">
                <img :src="getImageUrl('illustrations/error/404.png')" class="img-fluid" alt="" />
              </div>
            </div>
  
            <div class="col-md-8 mx-auto mt-3">
              <div class="page-container pass-forgot">
                <div>
                  <h2>Wah, Kamu Nyasar</h2>
                  <p>
                    Mungkin alamat tujuan kamu salah, yuk balik
                  </p>
                  <nuxt-link to="/" class="btn btn-solid-default">Kembali</nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  </template>
  
  <script lang="ts">
  import MainLayout from "~/layout/layouts/index.vue";
  export default {
    components: {
        MainLayout,
    },
    head() {
      return {
        title: "404",
      };
    },
  };
  </script>
  